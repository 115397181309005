import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";

class AboutMe extends React.Component {
  render() {
    return (
      <>
        <div className="page about-me">
          <div className="personal-information">
            <div className="left">
              <img src="/images/ivan-buccella.jpg" />
              <div className="icons-container">
                <a
                  className="link"
                  href="https://it.linkedin.com/in/ivanbuccella"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faLinkedinIn} size="2x" />
                </a>
                <a
                  className="link"
                  href="mailto:ivan@buccella.dev?subject=Mail from Website"
                >
                  <FontAwesomeIcon icon={faEnvelope} size="2x" />
                </a>
              </div>
            </div>
            <div className="right">
              <p className="role">Full Stack Developer</p>
              <h1 className="name">Ivan Buccella</h1>
              <h2 className="description">
                Hi! I am a Full Stack Developer with a passion for both front-end and back-end development, bringing technical expertise and a collaborative approach to every project. <br />
                Skilled in PHP, JavaScript, and Python, and experienced with frameworks like Flutter and Symfony, I not only deliver robust solutions but also provide structure and guidance to small teams.
                <br />
                As a strong advocate of "pair programming" and Agile teamwork, I believe growth and skill-building come from learning together and enhancing each other's strengths.
                <br />
                Known for my dedication to clean, organized code, effective project management, and task prioritization, I thrive in roles where teamwork, clarity, and continuous improvement drive success.
                <br />
                <br />
                Let's connect on{" "}
                <a
                  className="link"
                  href="https://it.linkedin.com/in/ivanbuccella"
                  target="_blank"
                >
                  Linkedin
                </a>
                &nbsp; or{" "}
                <a
                  className="link"
                  href="mailto:ivan@buccella.dev?subject=Mail from Website"
                >
                  email
                </a>
                .
              </h2>
              <div className="cta-container">
                <a
                  className="cta"
                  href="/files/CV-Ivan-Buccella.pdf"
                  target="_blank"
                >
                  Download CV
                </a>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default AboutMe;
